import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './OfferDetail.css';  // Import the CSS file for styling

const offers = [
  {
    permalink: '', // Add permalink
    title: {
      en: '', // Add English title
      it: '', // Add Italian title
      bn: ''  // Add Bengali title
    },
    description: {
      en: '', // Add English description
      it: '', // Add Italian description
      bn: ''  // Add Bengali description
    },
    details: {
      en: '', // Add English details
      it: '', // Add Italian details
      bn: ''  // Add Bengali details
    },
    image: '', // Add image URL
    validity: {
      en: '', // Add English validity period
      it: '', // Add Italian validity period
      bn: ''  // Add Bengali validity period
    },
    additionalInfo: {
      en: '', // Add English additional info
      it: '', // Add Italian additional info
      bn: ''  // Add Bengali additional info
    },
    terms: [
      {
        en: '', // Add English term
        it: '', // Add Italian term
        bn: ''  // Add Bengali term
      }
    ],
    faq: [
      {
        question: {
          en: '', // Add English question
          it: '', // Add Italian question
          bn: ''  // Add Bengali question
        },
        answer: {
          en: '', // Add English answer
          it: '', // Add Italian answer
          bn: ''  // Add Bengali answer
        }
      }
    ]
  }
];

const OfferDetail = () => {
  const { permalink } = useParams();  // Get permalink from URL
  const offer = offers.find((offer) => offer.permalink === permalink);
  const { t, i18n } = useTranslation();  // Get current language from i18n

  if (!offer) {
    return <h2>{t('offer_not_found')}</h2>;  // Handle offer not found case
  }

  // Get the current language (en, it, bn) from i18n
  const language = i18n.language;

  // Dynamically get the current URL
  const currentUrl = typeof window !== 'undefined' ? window.location.href : `https://www.wequi.xyz/campaign/${permalink}`;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{offer.title[language]} | WEQUI</title>
        <link rel="canonical" href={currentUrl} />
        <meta name="description" content={offer.description[language]} />

        {/* Open Graph Meta Tags */}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={offer.title[language]} />
        <meta property="og:description" content={offer.description[language]} />
        <meta property="og:image" content={offer.image} />

        {/* Twitter Meta Tags */}
        <meta property="twitter:url" content={currentUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={offer.title[language]} />
        <meta name="twitter:description" content={offer.description[language]} />
        <meta name="twitter:image" content={offer.image} />
      </Helmet>

      {/* Offer Details Section */}
      <section className="offer-details ptb100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src={offer.image} alt={offer.title[language]} className="img-fluid mb-4" />
              <h2>{offer.title[language]}</h2>

              <h3>{t('offer_validity')}</h3>
              <p>{offer.validity[language]}</p>

              <h3>{t('offer_details')}</h3>
              <ul className="terms-list">
                {offer.terms && offer.terms.map((term, index) => (
                  <li key={index}>{term[language]}</li>
                ))}
              </ul>

              <h3>{t('additional_info')}</h3>
              <p>{offer.additionalInfo[language]}</p>

              {/* FAQ Section */}
              <h3>{t('faq_title')}</h3>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>{t('question')}</th>
                    <th>{t('answer')}</th>
                  </tr>
                </thead>
                <tbody>
                  {offer.faq && offer.faq.map((faq, index) => (
                    <tr key={index}>
                      <td>{`${index + 1}. ${faq.question[language]}`}</td>
                      <td>{faq.answer[language]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Latest Campaigns Section */}
      <section className="latest-campaigns ptb100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>{t('latest_campaigns')}</h3>
              <div className="row">
                {offers.filter(o => o.permalink !== permalink).slice(0, 3).map((relatedOffer) => (
                  <div className="col-md-4 mb-4" key={relatedOffer.permalink}>
                    <Link to={`/campaign/${relatedOffer.permalink}`} className="card">
                      <img src={relatedOffer.image} alt={relatedOffer.title[language]} className="card-img-top" />
                      <div className="card-body">
                        <h5 className="card-title">{relatedOffer.title[language]}</h5>
                        <p className="card-text">{relatedOffer.description[language]}</p>
                        <p className="text-muted">{t('validity')}: {relatedOffer.validity[language]}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OfferDetail;
