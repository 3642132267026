import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  const currentUrl = typeof window !== 'undefined' ? window.location.href : 'https://www.wequi.xyz/NotFound'; // Default URL

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('notfound_page_title')}</title>
        <link rel="canonical" href={currentUrl} />
        <meta name="title" content={t('notfound_page_title')} />
        <meta name="description" content={t('notfound_page_title')} />
        <meta name="keywords" content={t('notfound_page_title')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph Meta Tags */}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('notfound_page_title')} />
        <meta property="og:description" content={t('notfound_page_title')} />
        <meta property="og:image" content="assets/images/logo.jpg" />

        {/* Twitter Meta Tags */}
        <meta property="twitter:url" content={currentUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('notfound_page_title')} />
        <meta name="twitter:description" content={t('notfound_page_title')} />
        <meta name="twitter:image" content="assets/images/logo.jpg" />
      </Helmet>

      <div id="main">
        <div className="fof">
          <h1>{t('notfound_page_title')}</h1>
          <p>{t('notfound_message')}</p>
          <Link to="/" className="btn btn-success">
            {t('go_back_home')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
