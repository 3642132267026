import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const baseUrl = window.location.origin; // Get the base URL dynamically

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('faq_page_title')} | WEQUI</title>
        <link rel="canonical" href={`${baseUrl}/Faq`} />
        <meta name="title" content={`${t('faq_page_title')} | WEQUI`} />
        <meta name="description" content="We've answered all of your frequently asked questions." />
        <meta name="keywords" content="wequi, wequi faq, frequently asked questions, faq" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta property="og:url" content={`${baseUrl}/Faq`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('faq_page_title')} | WEQUI`} />
        <meta property="og:description" content="We've answered all of your frequently asked questions." />
        <meta property="og:image" content={`${baseUrl}/assets/images/logo.jpg`} />

        <meta property="twitter:url" content={`${baseUrl}/Faq`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('faq_page_title')} | WEQUI`} />
        <meta name="twitter:description" content="We've answered all of your frequently asked questions." />
        <meta name="twitter:image" content={`${baseUrl}/assets/images/logo.jpg`} />
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('faq_page_title')}</h2>
                <p>{t('faq_page_description')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="accordion" id="accordionExample">
                {/* FAQ items */}
                {Array.from({ length: 6 }, (_, index) => (
                  <div className="card" key={index}>
                    <div className="card-header" id={`heading${index + 1}`}>
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={`#collapse${index + 1}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index + 1}`}
                        >
                          {t(`faq_question_${index + 1}`)}
                        </button>
                      </h2>
                    </div>
                    <div
                      id={`collapse${index + 1}`}
                      className="collapse"
                      aria-labelledby={`heading${index + 1}`}
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>{t(`faq_answer_${index + 1}`)}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
