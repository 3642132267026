import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Story = () => {
  const { t } = useTranslation();
  const currentUrl = typeof window !== 'undefined' ? window.location.href : 'https://www.wequi.xyz/Story'; // Default URL

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t('story_page_title')} | WEQUI`}</title>
        <link rel="canonical" href={currentUrl} />
        <meta name="title" content={`${t('story_page_title')} | WEQUI`} />
        <meta name="description" content="Learn about WEQUI’s mission to serve Bangladesh." />
        <meta name="keywords" content="about WEQUI" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph Meta Tags */}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('story_page_title')} | WEQUI`} />
        <meta property="og:description" content="Learn about WEQUI’s mission to serve Bangladesh." />
        <meta property="og:image" content="/assets/images/logo.jpg" />

        {/* Twitter Meta Tags */}
        <meta property="twitter:url" content={currentUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('story_page_title')} | WEQUI`} />
        <meta name="twitter:description" content="Learn about WEQUI’s mission to serve Bangladesh." />
        <meta name="twitter:image" content="/assets/images/logo.jpg" />
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('story_page_title')}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb100 pt60">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="job-details-info">
                <p align="justify">
                  <strong>{t('story_birth_of_wequi')}</strong><br />
                  {t('story_birth_of_wequi_desc')}
                </p>

                <p align="justify">
                  <strong>{t('story_anun_limited')}</strong><br />
                  {t('story_anun_limited_desc')}
                </p>

                <p align="justify">
                  <strong>{t('story_wequi_technologies')}</strong><br />
                  {t('story_wequi_technologies_desc')}
                </p>

                <p align="justify">
                  <strong>{t('story_mission')}</strong><br />
                  {t('story_mission_desc')}
                </p>

                <p align="justify">
                  <strong>{t('story_join_us')}</strong><br />
                  {t('story_join_us_desc')}
                </p>

                <p align="justify">{t('story_thank_you')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Story;
