import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';

const Header = () => {
    const { i18n } = useTranslation(); 
    const [isLanguageMenuVisible, setIsLanguageMenuVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    
    const languages = [
        { code: 'en', name: 'English', flag: 'gb' },
        { code: 'it', name: 'Italian', flag: 'it' },
        { code: 'bn', name: 'Bengali', flag: 'bd' }
    ];

    // Change language and apply corresponding font
    const changeLanguage = useCallback((lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
        setIsLanguageMenuVisible(false); // Close the menu after selection
    }, [i18n]);

    // Toggle language menu visibility
    const toggleLanguageMenu = () => {
        setIsLanguageMenuVisible(!isLanguageMenuVisible);
    };

    // Detect user's location based on IP and set language using geoiplookup.io
    const detectLanguageByIP = useCallback(async () => {
        try {
            const { data } = await axios.get('https://json.geoiplookup.io/');
            console.log("GeoIPLookup Response:", data); // Log the full response for debugging
            const countryCode = data.country_code;
            const countryName = data.country_name;

            const languageByCountry = {
                BD: { code: 'bn', name: 'Bengali', country: 'Bangladesh' },
                IT: { code: 'it', name: 'Italian', country: 'Italy' }
            };

            const detectedLanguage = languageByCountry[countryCode] || { code: 'en', name: 'English', country: 'your location' };

            changeLanguage(detectedLanguage.code);
            setPopupMessage(`We have selected <b>${detectedLanguage.name}</b> based on your location: <b>${countryName}</b>.`);
            setShowPopup(true);
        } catch (error) {
            console.error("Error detecting IP location:", error);
            changeLanguage('en'); // Default to English in case of error
            setPopupMessage('We have selected <b>English</b> based on your location.');
            setShowPopup(true);
        }
    }, [changeLanguage]);

    // Dismiss modal after 3.5 seconds
    useEffect(() => {
        if (showPopup) {
            const timer = setTimeout(() => {
                setShowPopup(false);
            }, 3500); // 3.5 seconds delay
            return () => clearTimeout(timer); // Clean up the timer on unmount
        }
    }, [showPopup]);

    // Detect language when the component is first mounted
    useEffect(() => {
        detectLanguageByIP();  // Detect the language based on location
    }, [detectLanguageByIP]);

    return (
        <div>
            {/* Navbar */}
            <nav className="navbar navbar-light navbar-expand-sm fixed-top">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand navbar-mobile" target="_parent">
                        <img src="assets/images/lll.svg" height={15} width={125} alt="logo" />
                    </Link>

                    {/* Globe icon for both desktop and mobile */}
                    <button className="btn text-white" type="button" onClick={toggleLanguageMenu} aria-expanded={isLanguageMenuVisible}>
                        <i className="fas fa-globe" style={{ fontSize: '20px', color: '#fff' }}></i> {/* Globe icon */}
                    </button>

                    {/* Language Menu - Floating Menu */}
                    {isLanguageMenuVisible && (
                        <div className="language-menu" style={{
                            position: 'absolute',
                            top: '50px',
                            right: '10px',
                            backgroundColor: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            zIndex: 1000,
                            borderRadius: '4px',
                            padding: '10px',
                            width: '170px',
                            maxWidth: '100%',  // Responsive width
                        }}>
                            {languages.map((lang) => (
                                <button
                                    key={lang.code}
                                    className="dropdown-item"
                                    onClick={() => changeLanguage(lang.code)}
                                    style={{ color: '#000', fontWeight: selectedLanguage === lang.code ? 'bold' : 'normal' }}
                                >
                                    <img
                                        src={`https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/${lang.flag}.svg`}
                                        height={20} width={30} alt={`${lang.name} flag`}
                                        style={{ marginRight: '8px' }}
                                    />
                                    {lang.name}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </nav>

            {/* Language Detection Popup */}
            <Modal isOpen={showPopup} contentLabel="Language Selection" style={{
                overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                content: {
                    padding: '20px',
                    borderRadius: '15px',
                    width: '90%',
                    maxWidth: '320px',
                    height: 'auto',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    background: '#f8f9fa',
                }
            }}>
                <h2 style={{ fontSize: '18px', marginBottom: '15px' }}>Language Selected</h2>
                <p style={{ fontSize: '14px', color: '#555' }} dangerouslySetInnerHTML={{ __html: popupMessage }}></p>
            </Modal>
        </div>
    );
};

export default Header;
