import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import i18n hook
import './CampaignPage.css'; // Import the CSS file for styles

// Blank offers structure with placeholders
const offers = [
  {
    title: {
      en: '', // English title
      it: '', // Italian title
      bn: ''  // Bengali title
    },
    description: {
      en: '', // English description
      it: '', // Italian description
      bn: ''  // Bengali description
    },
    permalink: '', // Add permalink here
    image: '',     // Add image URL here
  },
  {
    title: {
      en: '', // English title
      it: '', // Italian title
      bn: ''  // Bengali title
    },
    description: {
      en: '', // English description
      it: '', // Italian description
      bn: ''  // Bengali description
    },
    permalink: '', // Add permalink here
    image: '',     // Add image URL here
  },
  {
    title: {
      en: '', // English title
      it: '', // Italian title
      bn: ''  // Bengali title
    },
    description: {
      en: '', // English description
      it: '', // Italian description
      bn: ''  // Bengali description
    },
    permalink: '', // Add permalink here
    image: '',     // Add image URL here
  }
];

const CampaignPage = () => {
  const { t, i18n } = useTranslation(); // Use i18n hook for translations
  const language = i18n.language; // Get the current language

  // Get the current domain dynamically
  const currentDomain = window.location.origin; // This will give you the current domain

  return (
    <div>
      {/* Helmet for SEO and social sharing */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('campaign_offers_title')} | WEQUI</title>
        <meta name="description" content={t('campaign_offers_description')} />
        <meta name="keywords" content="WEQUI campaigns, discounts, offers" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph and Twitter Card for Social Sharing */}
        <meta property="og:url" content={`${currentDomain}/Campaign`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('campaign_offers_title') + " | WEQUI"} />
        <meta property="og:description" content={t('campaign_offers_description')} />
        <meta property="og:image" content={`${currentDomain}/assets/images/logo.jpg`} />
        <meta property="twitter:url" content={`${currentDomain}/Campaign`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('campaign_offers_title') + " | WEQUI"} />
        <meta name="twitter:description" content={t('campaign_offers_description')} />
        <meta name="twitter:image" content={`${currentDomain}/assets/images/logo.jpg`} />
      </Helmet>

      {/* Section title for the Campaign Page */}
      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('campaign_offers_title')}</h2>
                <p>{t('campaign_offers_description')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Campaign Offers */}
      <div className="container mt-5">
        <div className="row">
          {offers.map((offer) => (
            <div key={offer.permalink} className="col-md-6 mb-4">
              <Link to={`/Campaign/${offer.permalink}`} className="card offer-card">
                <img src={offer.image} className="card-img-top" alt={offer.title[language]} />
                <div className="card-body">
                  <h5 className="card-title">{offer.title[language]}</h5>
                  <p className="card-text">{offer.description[language]}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignPage;
