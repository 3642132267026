import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const year = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="footer-inner pt100 pb60">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <div className="footer-title">
                      <img src="assets/images/wequi2.svg" height={55} width={125} alt="Wequi Logo" />
                    </div>
                    <div className="footer-info">
                      <p>{t('footer_company_info')}</p>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="footer-title">
                      <h2>{t('quick_links')}</h2>
                    </div>
                    <div className="footer-menu">
                      <ul className="list-unstyled">
                        <li><Link to="/Campaign">{t('campaign_button')}</Link></li>
                        <li><Link to="/MerchantOnboard">{t('merchant_onboard')}</Link></li>

                        <li><Link to="/Livechat">{t('live_support')}</Link></li>
                        <li><Link to="/Contact">{t('contact_us')}</Link></li>

                      </ul>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="footer-title">
                      <h2>{t('company_info')}</h2>
                    </div>
                    <div className="footer-menu">
                      <ul className="list-unstyled">
                        <li><Link to="/Story">{t('our_story')}</Link></li>
                        <li><Link to="/Brand">{t('brand_guidelines')}</Link></li>
                        <li><Link to="/Leadership">{t('leadership')}</Link></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="footer-title">
                      <h2>{t('others')}</h2>
                    </div>
                    <div className="footer-menu">
                      <ul className="list-unstyled">
                        <li><Link to="/Privacy">{t('privacy_policy')}</Link></li>
                        <li><Link to="/Terms">{t('terms_of_use')}</Link></li>
                        <li>
                          <Link to="/Faq">
                            {t('faq')} <span className="badge badge-pill badge-light">Updated</span>
                          </Link>
                        </li>
                        <li><Link to="/sitemap" target="_parent">{t('sitemap')}</Link></li>
                        <li>
                          <i className="fas fa-map-marker-alt" /> {t('european_startup')}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="copy-right-part">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="footer-inner pt30 pb30">
                    <div className="row">
                      <div className="col-12 col-md-6 order-2">
                        <ul className="list-inline app-download-link">
                          <h3>
                            <span style={{ color: 'white' }}>{t('put_us_in_your_pocket')}</span>
                          </h3>
                          <li className="list-inline-item">
                            <a href="https://play.google.com/store/apps/details?id=xyz.wequi.wequi" target="_parent">
                              <img src="assets/images/play-store.png" alt="play store" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="https://play.google.com/store/apps/details?id=xyz.wequi.wequi" target="_parent">
                              <img src="assets/images/app-galary.png" height={150} width={138} alt="app gallery" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="https://play.google.com/store/apps/details?id=xyz.wequi.wequi" target="_parent">
                              <img src="assets/images/app-store.png" height={150} width={138} alt="app store" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="col-12 col-md-6 order-1">
                        <div className="copy-right">
                          <p>{t('made_with_love')}</p>
                          <p>
                            {t('copyright_text')} <i className="far fa-copyright" /> Wequi Technologies {year}. {t('all_rights_reserved')}
                          </p>
                          <p className="made-in-bd">
                            {t('company_number')}<span className="badge badge-light">14355185</span> {t('part_of_anun')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
