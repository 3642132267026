import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'; // Import useLocation

const Home = () => {
  const { t } = useTranslation();
  const location = useLocation(); // Get current location to detect route changes

  // Check if the component renders correctly without animations
  useEffect(() => {
    console.log('Home component rendered', location); // Log to see when it renders
  }, [location]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WEQUI | Social Commerce</title>
        <link rel="canonical" href="https://www.wequi.xyz" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="title" content="WEQUI | Social Commerce"></meta>
        <meta name="description" content="WEQUI is a Team Purchasing or Group Buying Social Commerce App." />
        <meta name="keywords" content="WEQUI, WEQUI app, team purchase Italy, social commerce Italy, savings app" />
        <meta itemProp="name" content="WEQUI | Social Commerce" />
        <meta itemProp="description" content="WEQUI is a Team Purchasing or Group Buying Social Commerce App." />
        <meta itemProp="image" content="https://www.wequi.xyz/assets/images/logo.jpg" />
        <meta property="og:url" content="https://www.wequi.xyz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="WEQUI | Social Commerce" />
        <meta property="og:description" content="WEQUI is a Team Purchasing or Group Buying Social Commerce App." />
        <meta property="og:image" content="https://www.wequi.xyz/assets/images/logo.jpg" />
        <meta property="twitter:url" content="https://www.wequi.xyz"></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="WEQUI | Social Commerce" />
        <meta name="twitter:description" content="WEQUI is a Team Purchasing or Group Buying Social Commerce App." />
        <meta name="twitter:image" content="https://www.wequi.xyz/assets/images/logo.jpg" />
      </Helmet>

      <div id="hero-area" className="hero-area-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="contents col-10 mx-auto text-center">
              <h1 className="header-title">
                <span>{t('header_title_1')}</span><br />
                <span>{t('header_title_2')}<br />{t('header_title_3')}</span>
              </h1>
              <div className="row">
                <h4 className="col-sm-7 col-md-6 mx-auto mb-4 text-white">
                  {t('hero_subtitle')}
                </h4>
              </div>

              <div className="header-button">
                
                <ul className="d-flex justify-content-center">
                  <li className="m-2">
                    <a href="https://play.google.com/store/apps/details?id=xyz.wequi.wequi" target="_parent">
                      <img src="assets/images/play-store.png" height="170" width="135" alt="play store" />
                    </a>
                  </li>
                  <li className="m-2">
                    <a href="https://play.google.com/store/apps/details?id=xyz.wequi.wequi" target="_parent">
                      <img src="assets/images/app-galary.png" height="170" width="135" alt="app gallery" />
                    </a>
                  </li>
                  <li className="m-2">
                    <a href="https://play.google.com/store/apps/details?id=xyz.wequi.wequi" target="_parent">
                      <img src="assets/images/app-store.png" height="170" width="135" alt="app store" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="fun-facts-section pt100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="fun-facts-inner ext">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="fun-facts-info">
                    <h2>{t('71_percent')}</h2>
                      <p>{t('fun_fact_savings')}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="fun-facts-info">
                    <h2>{t('99_percent')}</h2>
                      <p>{t('fun_fact_happiness')}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="fun-facts-info">
                    <h2>{t('100_percent')}</h2>
                      <p>{t('fun_fact_eco_friendly')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      

      <section id="new-features">
        <div className="new-feature" id="new-feature-1">
          <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-1.svg")' }} />
          <div className="container">
            <div className="col-lg-5 offset-lg-7">
              <h2>{t('team_purchase_title')}</h2>
              <p align="justify">{t('team_purchase_desc')}</p>
            </div>
          </div>
        </div>

        <div className="new-feature new-feature-reverse" id="new-feature-2">
          <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-2.svg")' }} />
          <div className="container">
            <div className="col-lg-5">
              <h2>{t('eco_friendly_title')}</h2>
              <p align="justify">{t('eco_friendly_desc')}</p>
            </div>
          </div>
        </div>

        <div className="new-feature" id="new-feature-3">
          <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-3.svg")' }} />
          <div className="container">
            <div className="col-lg-5 offset-lg-7">
              <h2>{t('app_access_title')}</h2>
              <p align="justify">{t('app_access_desc')}</p>
            </div>
          </div>
        </div>

        <div className="new-feature new-feature-reverse" id="new-feature-4">
          <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-4.svg")' }} />
          <div className="container">
            <div className="col-lg-5">
              <h2>{t('quick_redemption_title')}</h2>
              <p align="justify">{t('quick_redemption_desc')}</p>
            </div>
          </div>
        </div>

        <div className="new-feature" id="new-feature-5">
          <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-5.svg")' }} />
          <div className="container">
            <div className="col-lg-5 offset-lg-7">
              <h2>{t('deal_categories_title')}</h2>
              <p align="justify">{t('deal_categories_desc')}</p>
            </div>
          </div>
        </div>

        <div className="new-feature new-feature-reverse" id="new-feature-6">
          <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-6.svg")' }} />
          <div className="container">
            <div className="col-lg-5">
              <h2>{t('effortless_sharing_title')}</h2>
              <p align="justify">{t('effortless_sharing_desc')}</p>
            </div>
          </div>
        </div>
        
      </section>
    </div>
  );
};

export default Home;
