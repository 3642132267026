import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import itTranslation from './locales/it/translation.json';
import bnTranslation from './locales/bn/translation.json';


i18next.use(initReactI18next).init({
    resources: {
    en: {translation: enTranslation},
    it: {translation: itTranslation},
    bn: {translation: bnTranslation}
},
    lng: 'en', 
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }

})

export default i18next;