import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Footer from './Component/Footer';
import Header from './Component/Header';
import Brand from './pages/Brand';
import Faq from './pages/Faq';
import Home from './pages/Home';
import Leadership from './pages/Leadership';
import Livechat from './pages/Livechat';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy';
import Story from './pages/Story';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import MerchantOnboard from "./pages/MerchantOnboard";
import CampaignPage from './pages/CampaignPage';
import OfferDetail from './pages/OfferDetail';
import SitemapPage from './pages/SitemapPage';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'bn') {
      document.body.classList.add('bengali-font');
    } else {
      document.body.classList.remove('bengali-font');
    }
  }, [i18n.language]);

  return (
    <div>
      <Router>
        <ScrollToTop>
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/Brand' component={Brand} />
            <Route path='/Leadership' component={Leadership} />
            <Route path='/Livechat' component={Livechat} />
            <Route path='/Privacy' component={Privacy} />
            <Route path='/Story' component={Story} />
            <Route path='/Terms' component={Terms} />
            <Route path='/Faq' component={Faq} />
            <Route path='/Contact' component={Contact} />
            <Route path="/MerchantOnboard" component={MerchantOnboard} />
            <Route path='/Campaign' exact component={CampaignPage} />
            <Route path='/Campaign/:permalink' component={OfferDetail} />
            <Route path="/sitemap" component={SitemapPage} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
