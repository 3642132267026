import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Leadership = () => {
  const { t } = useTranslation();

  // Get current domain dynamically
  const currentDomain = typeof window !== 'undefined' ? window.location.origin : 'https://www.wequi.xyz';

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('leadership_page_title')} | WEQUI</title>
        <link rel="canonical" href={`${currentDomain}/Leadership`} />
        <meta name="title" content={`${t('leadership_page_title')} | WEQUI`} />
        <meta name="description" content={t('leadership_page_description')} />
        <meta name="keywords" content="leaders WEQUI, WEQUI leadership, WEQUI executive" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:url" content={`${currentDomain}/Leadership`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('leadership_page_title')} | WEQUI`} />
        <meta property="og:description" content={t('leadership_page_description')} />
        <meta property="og:image" content="/assets/images/logo.jpg" />
        <meta property="twitter:url" content={`${currentDomain}/Leadership`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('leadership_page_title')} | WEQUI`} />
        <meta name="twitter:description" content={t('leadership_page_description')} />
        <meta name="twitter:image" content="/assets/images/logo.jpg" />
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('leadership_profiles_italy')}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/fed.jpg" className="card-img-top" alt="federico" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('federico_title')}</h5>
                <div className="card-text text-black-50">{t('federico_position')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('leadership_profiles_group')}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/leader1.jpg" className="card-img-top" alt="masum" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('masum_title')}</h5>
                <div className="card-text text-black-50">{t('masum_position')}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/leader2.jpg" className="card-img-top" alt="tamal" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('tamal_title')}</h5>
                <div className="card-text text-black-50">{t('tamal_position')}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="assets/images/leader3.jpg" className="card-img-top" alt="hriday" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('hriday_title')}</h5>
                <div className="card-text text-black-50">{t('hriday_position')}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/leader4.jpg" className="card-img-top" alt="rafi" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('rafi_title')}</h5>
                <div className="card-text text-black-50">{t('rafi_position')}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/leader5.jpg" className="card-img-top" alt="tanvir" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('tanvir_title')}</h5>
                <div className="card-text text-black-50">{t('tanvir_position')}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/leader6.jpg" className="card-img-top" alt="rezaul" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('rezaul_title')}</h5>
                <div className="card-text text-black-50">{t('rezaul_position')}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/tanvir.jpg" className="card-img-top" alt="tanvirul" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('tanvirul_title')}</h5>
                <div className="card-text text-black-50">{t('tanvirul_position')}</div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-0 shadow">
              <img src="/assets/images/mortuza.jpg" className="card-img-top" alt="mahmudul" />
              <div className="card-body text-center">
                <h5 className="card-title mb-0">{t('mahmudul_title')}</h5>
                <div className="card-text text-black-50">{t('mahmudul_position')}</div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/imam.jpg" className="card-img-top" alt="imam" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">{t('imam_title')}</h5>
          <div className="card-text text-black-50">{t('imam_position')}</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/ib.jpg" className="card-img-top" alt="iqbal" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">{t('iqbal_title')}</h5>
          <div className="card-text text-black-50">{t('iqbal_position')}</div>
        </div>
      </div>
    </div>

        </div>
      </div>
    </div>
  );
};

export default Leadership;
