import React from 'react';
import { Link } from 'react-router-dom';
import './SitemapPage.css'; // Import a CSS file for styling

const SitemapPage = () => {
  const routes = [
    { path: '/', name: 'Home' },
    { path: '/Brand', name: 'Brand' },
    { path: '/Leadership', name: 'Leadership' },
    { path: '/Livechat', name: 'Live Chat' },
    { path: '/Partner', name: 'Partner' },
    { path: '/Privacy', name: 'Privacy Policy' },
    { path: '/Story', name: 'Our Story' },
    { path: '/Terms', name: 'Terms of Service' },
    { path: '/Faq', name: 'FAQ' },
    { path: '/Contact', name: 'Contact Us' },
    { path: '/MerchantOnboard', name: 'Merchant Onboarding' },
    { path: '/Campaign', name: 'Campaigns' },
    // Add any additional routes here
  ];

  return (
    <div className="sitemap-container">
      <h1>Sitemap</h1>
      <ul className="sitemap-list">
        {routes.map(route => (
          <li key={route.path} className="sitemap-item">
            <Link to={route.path}>{route.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SitemapPage;
