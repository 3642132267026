import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Livechat = () => {
  const { t } = useTranslation();
  const currentUrl = typeof window !== 'undefined' ? window.location.href : 'https://www.wequi.xyz/Livechat'; // Default URL

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('livechat_page_title')} | WEQUI</title>
        <link rel="canonical" href={currentUrl} />
        <meta name="title" content={`${t('livechat_page_title')} | WEQUI`} />
        <meta name="description" content={t('livechat_page_description')} />
        <meta name="keywords" content="WEQUI help, WEQUI helpline, WEQUI live chat" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph Meta Tags */}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('livechat_page_title')} | WEQUI`} />
        <meta property="og:description" content={t('livechat_page_description')} />
        <meta property="og:image" content="/assets/images/logo.jpg" />

        {/* Twitter Meta Tags */}
        <meta property="twitter:url" content={currentUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('livechat_page_title')} | WEQUI`} />
        <meta name="twitter:description" content={t('livechat_page_description')} />
        <meta name="twitter:image" content="/assets/images/logo.jpg" />
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('livechat_page_title')}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="join-section ptb100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <p>
                <iframe
                  title="WEQUI Live Chat"
                  id="livechat"
                  onLoad="setTimeout(function(){jQuery('.spinner').hide()}, 2000)"
                  src="https://tawk.to/chat/66808bdfeaf3bd8d4d166759/1i1j24oqt"
                  style={{ width: '100%', height: 610, border: 'none' }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Livechat;
