import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './Contact.css'; // Import the CSS file

const Contact = () => {
    const { t } = useTranslation(); // i18n hook for translations

    // Initialize state with a default country
    const [selectedCountry, setSelectedCountry] = useState('Italy');

    // Country-specific social media links
    const countrySocialMedia = {
        Italy: {
            facebook: "https://www.facebook.com/WEQUI-Italy",
            twitter: "https://www.twitter.com/WEQUI-Italy",
            instagram: "https://www.instagram.com/WEQUI-Italy",
            linkedin: "https://www.linkedin.com/company/WEQUI-Italy",
            telegram: "https://t.me/WEQUI-Italy"
        },
        'United Kingdom': {
            facebook: "https://www.facebook.com/WEQUI-UK",
            twitter: "https://www.twitter.com/WEQUI-UK",
            instagram: "https://www.instagram.com/WEQUI-UK",
            linkedin: "https://www.linkedin.com/company/WEQUI-UK",
            telegram: "https://t.me/WEQUI-UK"
        },
        Germany: {
            facebook: "https://www.facebook.com/WEQUI-Germany",
            twitter: "https://www.twitter.com/WEQUI-Germany",
            instagram: "https://www.instagram.com/WEQUI-Germany",
            linkedin: "https://www.linkedin.com/company/WEQUI-Germany",
            telegram: "https://t.me/WEQUI-Germany"
        }
        // Add more countries as needed
    };

    // Function to handle country change
    const handleCountryChange = (country) => {
        setSelectedCountry(country);
    };

    // Ensure selectedCountry is valid
    if (!countrySocialMedia[selectedCountry]) {
        return <div>Error: Country not found</div>;
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('contact_us_title')} | WEQUI</title>
                <link rel="canonical" href="https://www.wequi.xyz/Contact" />
                <meta name="title" content={`${t('contact_us_title')} | WEQUI`} />
                <meta name="description" content="Contact WEQUI support for assistance and inquiries." />
                <meta name="keywords" content="wequi contact, wequi support, contact us" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <meta property="og:url" content="https://www.wequi.xyz/Contact" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${t('contact_us_title')} | WEQUI`} />
                <meta property="og:description" content="Contact WEQUI support for assistance and inquiries." />
                <meta property="og:image" content="/assets/images/logo.jpg" />

                <meta property="twitter:url" content="https://www.wequi.xyz/Contact" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${t('contact_us_title')} | WEQUI`} />
                <meta name="twitter:description" content="Contact WEQUI support for assistance and inquiries." />
                <meta name="twitter:image" content="/assets/images/logo.jpg" />
            </Helmet>
            
            <section className="page-title pt115"> 
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title alt-left">
                                <h2>{t('contact_us_title')}</h2>
                            </div> 
                        </div>
                    </div>
                </div> 
            </section>

            <section className="contact-info ptb100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="office-info">
                                <h3>{t('italy_office_title')}</h3>
                                <p>
                                    {t('italy_office_address')} <br/>
                                    <strong>{t('email_label')}</strong> hello@wequi.xyz <br/>
                                    <strong>{t('help_line_label')}</strong> [Provide phone number] <br/>
                                    <strong>{t('live_chat_label')}</strong> <a href="/LiveChat">{t('live_chat_link')}</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="office-info">
                                <h3>{t('united_kingdom_office_title')}</h3>
                                <p>
                                    {t('united_kingdom_office_address')} <br/>
                                    <strong>{t('email_label')}</strong> hello@wequi.xyz <br/>
                                    <strong>{t('live_chat_label')}</strong> <a href="/LiveChat">{t('live_chat_link')}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="country-selector">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>{t('select_your_country')}</h3>
                            <div className="dropdown">
                                <button className="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {selectedCountry}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" onClick={() => handleCountryChange('Italy')}>Italy</button>
                                    <button className="dropdown-item" onClick={() => handleCountryChange('United Kingdom')}>United Kingdom</button>
                                    <button className="dropdown-item" onClick={() => handleCountryChange('Germany')}>Germany</button>
                                    {/* Add more countries here */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="social-media">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-center">{t('social_media_prompt')}</p>
                            <div className="social-icons text-center">
                                <a className="social-icon facebook" href={countrySocialMedia[selectedCountry].facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a className="social-icon twitter" href={countrySocialMedia[selectedCountry].twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a className="social-icon instagram" href={countrySocialMedia[selectedCountry].instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a className="social-icon linkedin" href={countrySocialMedia[selectedCountry].linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                                <a className="social-icon telegram" href={countrySocialMedia[selectedCountry].telegram} target="_blank" rel="noopener noreferrer" title="Telegram">
                                    <i className="fab fa-telegram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;
