import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { t } = useTranslation();
  const currentUrl = typeof window !== 'undefined' ? window.location.href : 'https://www.wequi.xyz/Terms'; // Default URL

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('terms_page_title')} | WEQUI</title>
        <link rel="canonical" href={currentUrl} />
        <meta name="title" content={`${t('terms_page_title')} | WEQUI`} />
        <meta name="description" content="To enjoy our services, you must agree to our terms and conditions." />
        <meta name="keywords" content="WEQUI terms and conditions, terms of use, user agreement" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph Meta Tags */}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('terms_page_title')} | WEQUI`} />
        <meta property="og:description" content="To enjoy our services, you must agree to our terms and conditions." />
        <meta property="og:image" content="/assets/images/logo.jpg" />

        {/* Twitter Meta Tags */}
        <meta property="twitter:url" content={currentUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('terms_page_title')} | WEQUI`} />
        <meta name="twitter:description" content="To enjoy our services, you must agree to our terms and conditions." />
        <meta name="twitter:image" content="/assets/images/logo.jpg" />
      </Helmet>

      <section className="page-title pt115">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="section-title alt-left">
                <h2>{t('terms_page_title')}</h2>
                <p align="justify">{t('terms_last_updated')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb100 pt60">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="job-details-info">
                <h3>{t('terms_intro_title')}</h3>
                <p align="justify">{t('terms_intro_desc')}</p>

                <h3>{t('terms_conditions_of_use_title')}</h3>

                <h4>{t('terms_account_title')}</h4>
                <p align="justify">{t('terms_account_desc')}</p>

                <h4>{t('terms_eligibility_title')}</h4>
                <p align="justify">{t('terms_eligibility_desc')}</p>

                <h4>{t('terms_responsibilities_title')}</h4>
                <p align="justify">{t('terms_responsibilities_desc')}</p>

                <h3>{t('terms_use_of_services_title')}</h3>

                <h4>{t('terms_license_title')}</h4>
                <p align="justify">{t('terms_license_desc')}</p>

                <h4>{t('terms_prohibited_conduct_title')}</h4>
                <p align="justify">{t('terms_prohibited_conduct_desc')}</p>

                <h4>{t('terms_content_title')}</h4>
                <p align="justify">{t('terms_content_desc')}</p>

                <h3>{t('terms_privacy_title')}</h3>
                <p align="justify">{t('terms_privacy_desc')}</p>

                <h3>{t('terms_intellectual_property_title')}</h3>
                <p align="justify">{t('terms_intellectual_property_desc')}</p>

                <h3>{t('terms_limitation_of_liability_title')}</h3>
                <p align="justify">{t('terms_limitation_of_liability_desc')}</p>

                <h3>{t('terms_dispute_resolution_title')}</h3>
                <p align="justify">{t('terms_dispute_resolution_desc')}</p>

                <h3>{t('terms_governing_law_title')}</h3>
                <p align="justify">{t('terms_governing_law_desc')}</p>

                <h3>{t('terms_contact_us_title')}</h3>
                <p align="justify">{t('terms_contact_us_desc')}</p>

                <p align="justify">{t('terms_agreement_notice')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Terms;
